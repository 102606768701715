// Updating product count display
const updateDisplayCount = () => {
    let count = $('.product-card[filter-valid="true"][search-valid="true"]').length;
    $('.products-list').attr('count', count);
    $('#results-count').text(`(${count})`);
};

const applyFilterHandler = () => {

    let categoryElt = $('span.toc-item.active');
    let activeCategory = categoryElt.attr('id').split('-').splice(-1)[0];
    let validProductsCount = 0;

    let cloudFilter = $('#cloud-filter').hasClass('dlt-tags__item--selected');
    let onPremisesFilter = $('#on-premises-filter').hasClass('dlt-tags__item--selected');
    let allDeployment = cloudFilter == onPremisesFilter;
    let recentFilter = $('#recent-filter').hasClass('dlt-tags__item--selected');

    $('.product-card').attr('filter-valid', 'false');

    $('.product-card').each((pos, elt) => {
        let isValid = true;
        let categoryId = $(elt).attr('category');
        let deploymentId = $(elt).attr('deployment');
        let recentId = $(elt).attr('recent');

        // left toc selection
        if (activeCategory != 'all' && categoryId != activeCategory) {
            isValid = false;
        }

        // deployment selection: cloud
        if (allDeployment == false){
            // Invalid if a product is either only cloud of only on-premises and the respective filter is not selected
            if(deploymentId.match(/premise/i) && !deploymentId.match(/cloud/i) && onPremisesFilter == false){
                isValid = false;
            }
            else if(!deploymentId.match(/premise/i) && deploymentId.match(/cloud/i) && cloudFilter == false){
                isValid = false;
            }
        }

        // recent selection
        if (recentFilter && recentId === 'false') {
            isValid = false
        }

        if (isValid) {
            validProductsCount += 1;
        }

        $(elt).attr('filter-valid', isValid);

    });

    updateDisplayCount();
    return;
}

//pressing enter triggers an implicit form submission. This result in clearing filter results. This prevent form submission when enter key is pressed
$(".product_filter").on("submit", function(event) {
    event.preventDefault(); // Stop form submission
});
 
// In-page search bar filtering
$("#product-guide-search").on('keyup change', (event) => {
    let currentVal = $(event.target).prop('value').trim().toLowerCase();
    //console.log(currentVal);
    if (currentVal) {
        $('.product-card').attr('search-valid', 'false');
        $(".product-card[data-tag^='" + currentVal + "'], .product-card[data-tag*='" + currentVal + "']").each((pos, elt) => {
            $(elt).attr('search-valid', 'true');
        });
    }
    else {
        $('.product-card').attr('search-valid', 'true');
    }
    
    // updating display count
    updateDisplayCount();
});

// Clearing in-page searchbar on click of cross button
$('.search-input-container #clear-btn').on('click', () => {
    $("#product-guide-search").prop('value', '');
    $('.product-card').attr('search-valid', 'true');
    updateDisplayCount();
    $("#product-guide-search").trigger('focus');
});

// Filter functionality
$('span.toc-item').on('click', (event) => {

    elt = $(event.target);
    let categoryId = elt.prop('id').split('-').slice(-1); // Example id: category-option-3

    $('span.toc-item.active').removeClass('active');
    elt.addClass('active');
    $('.quickfilter-search-container #title').text(elt.prop('title'));

    // changing filter dropdown value for smaller resolutions
    $(`.categories-filter-sm .chosen-single span`).html(elt.html());
    $('.categories-filter-sm .chosen-container').attr('chosen-category', elt.prop('id'));

    applyFilterHandler();
});

// Adding custom scroll bar
$(".toc").mCustomScrollbar({
    theme: "dark-2"
});

// Guides list - bug fix in malihu scroll bar
// $('.mCSB_draggerRail').each((pos, elt) => {
//     jElt = $(elt);
//     parent = jElt.closest('.mCSB_draggerContainer');
//     jElt.detach().appendTo(parent);
// })


$('#quickfilter .dlt-tags__item').on('click', (event) => {
    let elt = event.currentTarget;
    let val = $(elt).prop('id');

    $(elt).toggleClass('dlt-tags__item--selected');
    // $(elt).find('i').toggleClass('fa-sharp fa-solid fa-check');

    applyFilterHandler();
})


// Reset filter functionality
$('#reset-prodfam-filter-btn').on('click', () => {
    // Resetting to all topics guides
    $('span.toc-item.active').removeClass('active');
    $('span.toc-item#category-option-all').addClass('active');

    // Resetting search
    $("#product-guide-search").prop('value', '');

    // Resetting quickfilter
    $('#quickfilter .dlt-tags__item').removeClass('dlt-tags__item--selected');
    // $('#quickfilter .dlt-tags__item i').removeClass('fa-sharp fa-solid fa-check');

    // Resetting card display
    $('.product-card').attr('search-valid', 'true');
    $('.product-card').attr('filter-valid', 'true');

    // Resetting categories dropdown filter for smaller resolutions
    // changing filter dropdown value for smaller resolutions
    $(`.categories-filter-sm .chosen-single span`).html($('.toc-item#category-option-all').html());
    $('.categories-filter-sm .chosen-container').attr('chosen-category', 'category-option-all');

    // Resetting the title
    $('.quickfilter-search-container #title').html('All Products');

    // updating count display value
    updateDisplayCount();
})

/*handle product click. When a product is clicked, check if there is a landing page
1. if there is a landing page, open the landing page
2. if there is no landing page, open overview page from the new location
3. if there is no landing page and overview page, use filepath from database. 
*/
handleProductClick = (productId) => {
    const prodId = productId.toLowerCase();

    // make an AJAX call to send product id to the server
   $.ajax({
        url: "product/" + prodId,
        type: 'GET',
        dataType: 'json', //response is in JSON 
        success: function (response){
            if (response.LP == 'Y')
            {
                console.log("Landing page found");
                console.log(response.landingPage);
                window.location.href= response.landingPage;
            }
            else 
            {
                if (response.PO == 'Y')
                {
                  //window.location.href= prodId + "/" + response.version + "/" + response.language + "/webhelp/" + prodId + "-webhelp" + "/overview.html";
                  console.log("Overview page found");
                  console.log(response.overviewPage);
                  window.location.href= "/" + response.overviewPage;
                }
                //if there is no landing and overview page, use filepath from database.
                else 
                {
                    console.log("There is no landing and overview page found for this product. Use the file path from database.");
                    console.log(response.filePath);
                    window.location.href= "/" + response.filePath;
                }
            } 
            //1. window.location.href= prodId + "/" + response.version + "/" + response.language + "/index.html";
            //2. window.location.href= prodId + "/" + response.version + "/" + response.language + "/webhelp/" + prodId + "-webhelp" + "/index.html";
            //3. window.location.href= response.filePath 
        }, 
        error: function (xhr, status, error){
            console.error('Error from error block fetching file', error);
        }
    });
//}
}
$(window).on('load', () => {

    // Adding dropdown icon to categories filter
    let dropdownIcon = $('.categories-filter-sm .chosen-single i')
    dropdownIcon.removeClass('dlt-icon-dropdown').addClass('fa-solid fa-caret-down');

    // Updating active option for categories dropdown
    $('.categories-filter-sm .chosen-single').on('click touch', (event) => {
        console.log('dropdown clicked', event.type);
        let elt = $(event.target);
        let chosenCategoryId = elt.closest('.chosen-container').attr('chosen-category');

        chosenCategoryId ? $(`.categories-filter-sm .active-result.result-selected:not(#sm-${chosenCategoryId})`).removeClass('result-selected highlighted') : '';
        let newSeletion = $(`.categories-filter-sm .active-result#sm-${chosenCategoryId}`);
        newSeletion ? newSeletion.addClass('result-selected highlighted') : '';
    });

    // Filter functionality for categories dropdown
    $('.categories-filter-sm .chosen-results').on('click vclick touch touchstart', (event) => {
        console.log('result option clicked:', event.type);
        elt = $(event.target);
        if (elt.hasClass('active-result')) {
            console.log(elt.prop('id').split('-').slice(-1));
            let categoryId = elt.prop('id').split('-').slice(-1);

            // changing left toc 
            $('.toc-item.active').removeClass('active');
            leftTocElt = $(`.toc-item#category-option-${categoryId}`);
            leftTocElt.addClass('active');
            $('.quickfilter-search-container #title').text(leftTocElt.attr('title'));

            // Updating label
            $('.categories-filter-sm .chosen-container').attr('chosen-category', `category-option-${categoryId}`);

            // applying filter
            applyFilterHandler();
        }
    });
})