/*
 * Welcome to your app's main JavaScript file!
 *
 * This file will be included onto the page via the importmap() Twig function,
 * which should already be in your base.html.twig.
 */

// Custom Styling
import './styles/base.css';

// ---- Global partials styling
import './styles/partials/root-nav.css';
//import './styles/partials/feedback-form.css';

// ---- Home page stylings
import './styles/home/index.css';
import './styles/home/partials/landing-banner.css';
import './styles/home/partials/business-unit-card.css';

// ---- Product family landing page styling
import './styles/business_unit/index.css';
import './styles/business_unit/partials/_product_card.css';

// ---- Search results styling
import './styles/search/index.css';

// Third-party modules
import './bootstrap.js';

import './styles/vendor/jquery.mCustomScrollbar.min.css';
import './js/vendor/jquery.mCustomScrollbar.concat.min';

// Import custom styling
import './js/business_unit/index.js';
//import './js/partials/_feedback-form.js';
import './js/home/partials/_landing-banner.js';
import './js/search/index.js';

//console.log('This log comes from assets/app.js - welcome to AssetMapper! 🎉');
